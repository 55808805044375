import React, { lazy, Suspense } from "react"
import { Link } from "gatsby"
import SiteNavigation from "../nav-new/nav"
import Image from "../image-component/image-2023"
import FormSection from "./homepage-form"
import CountUp from "react-countup"
import { useInView } from "react-intersection-observer"
import FallbackLoading from "../loader/loader"
import HeroLoader from "../loader/hero-animation-loader"
import "./homepage.scss"
const SliderSection = lazy(() => import("./slider"))
const HeroAnimation = lazy(() => import("./hero-animation"))
const PredictionAnimation = lazy(() => import("./prediction-animation"))

//const FormSection = lazy(() => import('./homepage-form'))
const DevStack = lazy(() => import("./dev-stack"))

export default function Homepage() {
  const { ref, inView } = useInView({
    threshold: 0,
  })
  const isSSR = typeof window === "undefined"
  return (
    <>
      <section className={"hero-section"}>
        <SiteNavigation />
        <div className="hero-container">
          <div className="hero-left">
            <div className="super-heading">Predictive CRO for B2B SaaS</div>
            <div className="hero-heading">
              Convert 30% more in just 90 days. Guaranteed.
            </div>
            <div className="hero-content">
              <p>
                A/B test with a{" "}
                <Link to="/#prediction-engine" title="prediction engine">
                  prediction engine
                </Link>{" "}
                that harnesses the collective wisdom of testing on 34,000
                websites. Plus a{" "}
                <Link to="/#full-service-team" title="Our team">
                  full service team
                </Link>{" "}
                that handles all design and dev. So you get more wins and faster
                results.
              </p>
              <p>
                <Link to="/#performance-pricing" title="performance pricing">
                  {" "}
                  Performance pricing.
                </Link>{" "}
                No fee until we deliver.
              </p>
            </div>
            <Link className="arrow-button" to="/get-demo/">
              Get a Demo{" "}
            </Link>
          </div>
          <div className="hero-right">
            {!isSSR && (
              <Suspense fallback={<HeroLoader />}>
                <HeroAnimation />
              </Suspense>
            )}
          </div>
        </div>
      </section>
      <section className="social-proof-section">
        <Image
          sourceFolder={"homepage"}
          lazyLoad={"false"}
          alt={"Social proof"}
          fallBackImage={"2023-social-logos-mobile.webp"}
          imgName={[
            {
              imageName: "2023-social-logos-desktop.webp",
              minBreakpoint: "992px",
            },
            {
              imageName: "2023-social-logos-tablet.webp",
              minBreakpoint: "768px",
            },
            {
              imageName: "2023-social-logos-mobile.webp",
              minBreakpoint: "320px",
            },
          ]}
        />
      </section>
      <section className="how-it-works">
        <div className="how-heading">How it works</div>
        <div className="how-subheading">
          See how Predictive CRO gets you bigger and faster wins than legacy
          CRO. Results guaranteed!
        </div>
        <div className="row-wrap" id="prediction-engine">
          <div className="row-left">
            {!isSSR && (
              <Suspense fallback={<FallbackLoading />}>
                <PredictionAnimation />
              </Suspense>
            )}
          </div>
          <div className="row-right">
            <div className="row-heading">
              <span>Prediction engine.</span>
              <br />
              Finds proven winners.
            </div>
            <div className="row-text">
              <p>
                Spiralyze scrapes A/B test data from the 34,000 sites that
                actively test to learn what wins. Machine learning uses that
                data to predict what wins on your sites.
              </p>
              <p>Get 3X higher win rates than legacy A/B testing.</p>
            </div>
            <Link className="arrow-button-outline" to="/prediction-engine/">
              Prediction Engine{" "}
            </Link>
          </div>
        </div>
        <div className="row-wrap reverse" id="full-service-team">
          <div className="row-left">
            <div className="row-heading">
              <span>Full service team.</span>
              <br />
              Runs more A/B tests.
            </div>
            <div className="row-text">
              <p>
                Get all implementation handled by a team of 160 A/B testing
                specialists. Design, copywriting, development, QA, research,
                analytics, and project management. All software included.
              </p>
              <p>Your first test live in just 2 weeks.</p>
            </div>
            <Link className="arrow-button-outline" to="/full-service-team">
              Full Service Team{" "}
            </Link>
          </div>
          <div className="row-right">
            <Image
              sourceFolder={"homepage"}
              lazyLoad={"true"}
              alt={"Full service team"}
              fallBackImage={"2023-full-service-desktop.webp"}
              imgName={[
                {
                  imageName: "2023-full-service-desktop.webp",
                  minBreakpoint: "992px",
                },
                {
                  imageName: "2023-full-service-tablet.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "2023-full-service-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
          </div>
        </div>
        <div className="row-wrap" id="performance-pricing">
          <div className="row-left stats">
            <Image
              sourceFolder={"homepage"}
              lazyLoad={"true"}
              alt={"results"}
              fallBackImage={"2023-bg-imag_1.png"}
              imgName={[
                {
                  imageName: "2023-bg-imag_1.png",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "2023-results-bg-mobile.png",
                  minBreakpoint: "320px",
                },
              ]}
            />
            <div className="animated-stats" ref={ref}>
              <div className="stat-item">
                <div className="num-val">
                  <CountUp end={10} enableScrollSpy={true} />
                </div>
                <div className="gradient-bar-wrap">
                  <div className="bar-desc">
                    Tests Launched (in first 90 days)
                  </div>
                  <div
                    className={
                      inView ? "gradient-bar animated" : "gradient-bar"
                    }
                  ></div>
                </div>
              </div>
              <div className="stat-item">
                <div className="num-val">
                  <CountUp end={34} enableScrollSpy={true} />%
                </div>
                <div className="gradient-bar-wrap">
                  <div className="bar-desc">Test Win Rate</div>
                  <div
                    className={
                      inView ? "gradient-bar animated" : "gradient-bar"
                    }
                  ></div>
                </div>
              </div>
              <div className="stat-item">
                <div className="num-val">
                  <CountUp end={38} enableScrollSpy={true} />%
                </div>
                <div className="gradient-bar-wrap">
                  <div className="bar-desc">Average Lift</div>
                  <div
                    className={
                      inView ? "gradient-bar animated" : "gradient-bar"
                    }
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row-right">
            <div className="row-heading">
              <span>Guaranteed results.</span>
              <br />
              30% lift in 90 days.
            </div>
            <div className="row-text">
              <p>
                By adapting proven winners we can guarantee results. We don't
                get paid until you get the promised lift.
              </p>
              <p>10 tests, 3 wins, 30% lift in your first 90 days.</p>
            </div>
            <Link className="arrow-button-outline" to="/pricing/">
              Performance Pricing{" "}
            </Link>
          </div>
        </div>
      </section>
      <FormSection />
      {!isSSR && (
        <Suspense fallback={<FallbackLoading />}>
          <DevStack />
        </Suspense>
      )}
      <section className="saas-experts">
        <div className="saas-heading">B2B SaaS experts</div>
        <div className="saas-subheading">
          We specialize in high growth B2B SaaS companies. Typically, from
          Series B through to post-IPO. If you spend over $50k/month on paid
          media, we should talk.
        </div>
        <div className="stats-bg">
          <div className="stats-wrap">
            <div className="stat-item gold">
              <Image
                sourceFolder={"homepage"}
                imgName={"2023-people-icon.webp"}
                alt={"icon"}
              />
              <div className="stat-value">216</div>
              <div className="stat-name">Clients Served</div>
            </div>
            <div className="stat-item green">
              <Image
                sourceFolder={"homepage"}
                imgName={"2023-test-run.webp"}
                alt={"icon"}
              />
              <div className="stat-value">6,700</div>
              <div className="stat-name">Tests Run</div>
            </div>
            <div className="stat-item blue">
              <Image
                sourceFolder={"homepage"}
                imgName={"2023-value-created.webp"}
                alt={"icon"}
              />
              <div className="stat-value">$2.8B</div>
              <div className="stat-name">Value Created</div>
            </div>
          </div>
          <Link className="arrow-button" to="/get-demo/">
            Get a Demo{" "}
          </Link>
        </div>
      </section>
      {!isSSR && (
        <Suspense fallback={<FallbackLoading />}>
          <SliderSection />
        </Suspense>
      )}
    </>
  )
}
