import React from "react"
import Image from "../image-component/image-2023"

export default function FallbackLoading() {
  return <Image
    sourceFolder={'homepage'}
    lazyLoad={"true"}
    alt={"hero animation"}
    fallBackImage={'2023-animation-placeholder-desktop.webp'}
    imgName={[
      {
        imageName: "2023-animation-placeholder-desktop.webp",
        minBreakpoint: "992px",
      },
      {
        imageName: "2023-animation-placeholder-tablet.webp",
        minBreakpoint: "768px",
      },
      {
        imageName: "2023-animation-placeholder-mobile.webp",
        minBreakpoint: "320px",
      },
    ]}
  />
}